import React, {useEffect, useState} from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import {db} from "../firebase"

const LeadsPage = () => {
  const [leads , setLeads] = useState([]);
  useEffect( 
    () => {db.collection("Leads").onSnapshot(snapshot => {setLeads(snapshot.docs.map(doc => {
      const { fullName , email, phoneNumber} = doc.data();
      return(
      <tr key={doc.id}>
        <td>{fullName}</td>
        <td>{phoneNumber}</td>
        <td>{email}</td>
      </tr>  )
         }))});
        }, [])

  return(
  <Layout>
    <Seo title="Leads Data" />

    <section>
      <div className="container">
        <table>
          <thead>
            <tr>
              <th>שם מלא</th>
              <th>טלפון</th>
              <th>דואר אלקטרוני</th>
            </tr>
          </thead>
          <tbody>
            {leads}
          </tbody>
        </table>
      </div>
    </section>

  </Layout>
)}

export default LeadsPage 